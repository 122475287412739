import React from 'react';

const Header = () => (
  <nav className="navbar navbar-expand-sm justify-content-center mt-2 mb-0">
    <a className="navbar-brand mx-2" href="#">Washington</a> |
    <a className="navbar-brand mx-2" href="https://ca.realtorcheck.com">California</a>
  </nav>
);

export default Header;
